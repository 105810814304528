import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';

//Assets
/*
  Fisrt approved GIF
  import AGV_frames from '../../../../../asset/resource/AGV_frames.gif';
*/

import primaryVideo from '../../../../../asset/resource/pallette.webm';
import safariVideo from '../../../../../asset/resource/pallette.mov';
// import partnerBodyLogo from '../../../../../asset/resource/omniverse-logo.png';
// import partnerBodyLogo from '../../../../../asset/resource/partner-logo.svg';
import partnerBodyLogo from '../../../../../asset/resource/omniverse-logo.png';

import { data } from './data';

import { useCheckMobile } from '../../../../hooks';

import './Title.scss';

export const Title = () => {
  const isMobile = useCheckMobile({ width: 767 });
  const [isStart, setIsStart] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);

  useEffect(() => {
    let isSafari = window.navigator.vendor.toLowerCase().indexOf('apple') > -1;
    if (isSafari) {
      setIsSafariBrowser(true);
    }
  }, [isSafariBrowser]);

  return (
    <section className="main-title">
      <div className="main-title__wrapper">
        <div className="main-title__heading">
          <div className={cn('main-title__description', isStart && 'visible')}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { y: 0, opacity: 1 },
                    }
                  : {
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: -200, opacity: 0 },
                    }
              }
              transition={{ type: 'anticipate', duration: 0.5 }}
            >
              <h1>{data.title}</h1>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              onAnimationComplete={() => setIsStart(true)}
              variants={
                isMobile
                  ? {
                      visible: { y: 0, opacity: 1 },
                    }
                  : {
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: -50, opacity: 0 },
                    }
              }
              transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
            >
              <span className="main-title__description_colored-text">
                {data.description}
              </span>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { y: 0, opacity: 1 },
                    }
                  : {
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: -200, opacity: 0 },
                    }
              }
              transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
            >
              <p>{data.subDescription}</p>
            </motion.div>
          </div>

          <motion.img
            fetchpriority="high"
            initial={{ opacity: 0, y: -150 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className="main-title__body-logo"
            src={partnerBodyLogo}
            alt="NVidia Omniverse logo"
          />

          <div className="main-title__inview">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              onAnimationComplete={() => setIsStart(true)}
              variants={
                isMobile
                  ? {
                      visible: { x: 0, y: 0, opacity: 1 },
                    }
                  : {
                      visible: {
                        x: 0,
                        y: 0,
                        opacity: 1,
                      },
                      hidden: {
                        x: 300,
                        y: 0,
                        opacity: 0,
                      },
                    }
              }
              transition={{ type: 'spring', duration: 2.0, delay: 0.3 }}
            >
              <video
                id="heroVideo"
                className="main-title__hero-image"
                loop
                muted
                playsInline
                autoPlay
              >
                {isSafariBrowser ? (
                  <source src={safariVideo} type="video/mp4" />
                ) : (
                  <source src={primaryVideo} type="video/webm" />
                )}
              </video>
              {/* <img src={palletGifSloww} alt="" className="main-title__hero-image" /> */}
            </motion.div>
          </div>
        </div>

        <div className="main-title__items">
          <div
            className={cn('main-title__item-icons', isVisible && 'add-line')}
          >
            {data.items.map(({ name, image }, index) => (
              <motion.div
                key={index}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                onAnimationStart={() => setIsVisible(true)}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{
                  type: 'anticipate',
                  duration: 1,
                  delay: 0.3 + index * 0.1,
                }}
              >
                <img src={image} alt="" />
              </motion.div>
            ))}
          </div>
          <div className="main-title__item-text">
            {data.items.map(({ number, title, description }, index) => (
              <motion.div
                key={number}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{
                  type: 'anticipate',
                  duration: 1,
                  delay: 0.5 + index * 0.1,
                }}
              >
                <h3>{title}</h3>
                <p>{description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Title;
