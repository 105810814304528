import generative_ai_icon from '../../../../../asset/resource/generative-ai-icon.png';
import nividia_omniverse_icon from '../../../../../asset/resource/nividia-omniverse-icon.png';
import process_simulation_icon from '../../../../../asset/resource/process-simulation-icon.png';
import issac_sim_icon from '../../../../../asset/resource/isaac-sim-icon.png';

export const data = {
  items: [
    {
      image: nividia_omniverse_icon,
      number: 'Item 1',
      description:
        'A platform that integrates all aspects of the warehouse digital twin, including 3D assets, physics, discrete events, and more, into a comprehensive ecosystem, leveraging Open USD. Omniverse facilitates the development, deployment, and management of advanced 3D applications and pipelines. It enables a photo-realistic view and intuitive analysis of warehouse operations.',
    },
    {
      image: issac_sim_icon,
      number: 'Item 2',
      description:
        'The high-fidelity physical simulation, combined with emulated processes control logic, helps explore what-if scenarios, predict future events, mitigate operational risks, and optimize operations. ',
    },
    {
      image: process_simulation_icon,
      number: 'Item 3',
      description:
        'Tools and libraries for macro-level simulations of material flow and logistics process simulations of the warehouse in conjunction with optimization techniques.  ',
    },
    {
      image: generative_ai_icon,
      number: 'Item 4',
      subtitles: ['NVIDIA NeMo™', 'NVIDIA<sup>®</sup> Riva'],
      description:
        'WarehouseSim uses generative AI to recognize natural speech from users and process it into actionable commands, allowing the instant transformation of the generated layout. Create and modify 3D objects in NVIDIA omniverse using natural languages or text. ',
    },
  ],
};
