import slide_image_1 from '../../../../../asset/resource/slide_image_1.png';
import slide_image_2 from '../../../../../asset/resource/slide_image_2.png';
import slide_image_3 from '../../../../../asset/resource/slide_image_3.png';
import slide_image_4 from '../../../../../asset/resource/slide_image_4.png';
import slide_image_5 from '../../../../../asset/resource/slide_image_5.png';
import slide_image_6 from '../../../../../asset/resource/slide_image_6.png';

export const data = {
  title: 'Smart Agricultural Robotics',
  subTitle: 'Use Cases',
  items: [
    {
      image: slide_image_1,
      number: 'Item 1',
      title: 'Accurate Layout Creation:',
      description: `<ul>
        <li>Digital representation for precise layout creation, considering factors like equipment placement, aisle width, and storage capacity.</li>
        <li>Eliminates potential design flaws and ensures optimized warehouse layout.</li>
        </ul>`,
    },
    {
      image: slide_image_2,
      number: 'Item 2',
      title: 'Maximized Floor Space and Improved Inventory Categorization:',
      description: `<ul>
        <li>Simulate various storage configurations to identify the most efficient placement of goods and equipment.</li>
        <li>Experiment with shelving heights, rack designs, and storage systems to maximize storage capacity and ensure easy accessibility. </li>
        <li>Analyze the impact on workflow, picking paths, and order fulfillment to determine efficient material flow patterns. </li>
        </ul>`,
    },
    {
      image: slide_image_3,
      number: 'Item 3',
      title: 'Robotization & Automation Simulations: ',
      description: `<ul>
          <li>Navigation optimization, minimizing collisions and travel time.</li>
          <li>Simulate AGVs and AMRs movement and operations to understand how they operate in real life, including post-mortem analysis and troubleshooting. </li>
          <li>Automatic order fulfillment. </li>
        </ul>`,
    },
    {
      image: slide_image_6,
      number: 'Item 4',
      title: 'Optimization and operations planning: ',
      description: `<ul>
          <li>Ensure productivity and throughput and reduce costs by leveraging what-if modeling and optimization.</li>
          <li>Layout and materials flow optimization, sensitivity analysis to evaluate the impact of planned changes. </li>
          <li>Optimizing collaborative efficiency between Autonomous Mobile Robots (AMRs) and personnel.</li>
          <li>Advanced visualizations (e.g., AMR movement heat map, inventory turnover, etc.)</li>
        </ul>`,
    },
    {
      image: slide_image_4,
      number: 'Item 5',
      title: 'Enhanced Safety and Ergonomics: ',
      description: `<ul>
          <li>Simulate potential hazards, such as collision risks and obstruction of emergency exits, to identify safety concerns early in the design phase. </li> 
          <li>Assess ergonomic considerations, such as workstation placement, lifting aids, and equipment, to identify and resolve potential issues. </li>
        </ul>`,
    },
    {
      image: slide_image_5,
      number: 'Item 6',
      title: 'Streamlined Collaboration and Communication: ',
      description: `<ul>
          <li>Common visual language through 3D visualization bridges the gap between technical experts and non-technical stakeholders.  </li>
          <li>Facilitates collaborative decision-making by providing a virtual representation of the warehouse.  </li>
          <li>Visualization promotes a shared understanding among designers, logistics professionals, and decision-makers.  </li>
        </ul>`,
    },
  ],
};
