import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer/Footer';
import FormSection from './sections/FormSection/FormSection';
import Title from './sections/Title/Title';
import HowItWorks from './sections/HowItWorks/HowItWorks';
import UseCases from './sections/UseCases/UseCases';
import TryItNow from './sections/TryItNow/TryItNow';

import './Main.scss';

function Main() {
  return (
    <div className="main" id="home">
      <Header />
      <Title />
      <HowItWorks />
      <UseCases />
      {/* <TryItNow /> */}
      <FormSection />
      <Footer />
    </div>
  );
}

export default Main;
