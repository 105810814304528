import React, { useState, useEffect, useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { sanitizeHtml } from 'utils';

//Assets
import { data } from './data';

import { useCheckMobile } from '../../../../hooks';

import './HowItWorks.scss';

export const HowItWorks = () => {
  const isMobile = useCheckMobile({ width: 767 });
  const blocksRef = useRef();
  const isBlocksInView = useInView(blocksRef);
  const [animationTriggered, setTriggeredStatus] = useState(false);

  useEffect(() => {
    if (isBlocksInView) {
      setTriggeredStatus(true);
    }
    return;
  }, [isBlocksInView]);

  // const descriptionAnimation = {
  //   initial: { x: -200, opacity: 0 },
  //   animate: isBlocksInView
  //     ? isMobile
  //       ? { x: 0, opacity: 1 }
  //       : { x: 0, opacity: 1 }
  //     : animationTriggered
  //     ? { x: 0, y: 0, opacity: 1 }
  //     : {},
  //   transition: { duration: 1, delay: 0.1 },
  //   viewport: { once: true },
  // };

  const descriptionAnimation = {
    initial: { x: isMobile ? 0 : -200, opacity: isMobile ? 1 : 0 },
    animate: isBlocksInView
      ? { x: 0, opacity: 1 }
      : animationTriggered
      ? { x: 0, opacity: 1 }
      : {},
    transition: { duration: 1, delay: 0.1 },
  };

  return (
    <section id="technology" className="how-it-works">
      <div className="how-it-works__wrapper">
        <motion.div {...descriptionAnimation}>
          <h2>
            <span>THE TECHNOLOGY</span>
          </h2>
        </motion.div>
        <motion.div {...descriptionAnimation} viewport={{ once: true }}>
          <p className="how-it-works__description">
            We provide a unified digital platform to onboard and integrate all
            aspects of the warehouse into a comprehensive warehouse digital
            twin.
          </p>
        </motion.div>
        <div ref={blocksRef} className="how-it-works__blocks">
          {data.items.map(
            ({ number, image, description, subtitles }, index) => (
              <motion.div
                key={number}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { x: 0, opacity: 1 },
                      }
                    : {
                        visible: {
                          x: index === 1 || index === 2 ? 145 : 0,
                          opacity: 1,
                        },
                        hidden: { x: -500, opacity: 0 },
                      }
                }
                transition={{
                  type: 'anticipate',
                  duration: 2,
                  delay: 0.5 + index * 0.2,
                }}
              >
                <article className="how-it-works__card">
                  <figure className="how-it-works__image">
                    <img width="100%" src={image} alt="" />
                  </figure>
                  {subtitles && (
                    <ol>
                      {subtitles.map((st) => (
                        <li
                          key={st}
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(st),
                          }}
                        ></li>
                      ))}
                    </ol>
                  )}
                  <p>{description}</p>
                </article>
              </motion.div>
            ),
          )}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
