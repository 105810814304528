import React, { useState } from 'react';
import { get, isEmpty } from 'lodash';
import axios from 'axios';
import { ReCaptcha } from 'react-recaptcha-v3';

import { Form, Field } from 'components/Form';
import validation from './validation.js';

import './RegistrationForm.scss';

export const RegistrationForm = () => {
  const [values, setValues] = useState({
    data: {},
    errors: {},
    status: '',
  });

  const validate = () => {
    const errors = validation(values.data);

    if (!isEmpty(errors)) {
      setValues((prevState) => ({
        ...prevState,
        errors: {
          ...errors,
        },
      }));
    }

    return isEmpty(errors);
  };

  const onChangeField = (event) => {
    const { name, type, value, checked } = event.target;
    const fieldValue =
      type === 'checkbox' || type === 'radio' ? checked : value;

    setValues((prevState) => ({
      ...prevState,
      data: {
        ...values.data,
        [name]: fieldValue,
      },
    }));
  };

  const onVerifyCaptcha = (token) => {
    setValues((prevState) => ({
      ...prevState,
      data: {
        ...values.data,
        ['captcha']: token,
      },
    }));
  };

  const submitForm = (event) => {
    event.preventDefault();
    const portalId = '2744722';
    const hubspotFormId = '4bf82c36-2eee-48c2-9363-cf40f701f7b0';
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${hubspotFormId}`;

    const formValues = {
      fields: Object.entries(values.data).map((item) => ({
        name: item[0],
        value: item[1],
      })),
      context: {
        pageUri: 'https://warehouseviz.softserveinc.com',
        pageName: 'Warehouse Digital Twin NVIDIA Demo',
        hutk: 'bc443d84085c8fcf189a9afd50483099',
      },
      skipValidation: true,
    };

    const postForm = (endpoint, params) =>
      axios({
        method: 'POST',
        data: params,
        url: endpoint,
      });

    if (validate()) {
      postForm(url, formValues).then(() => {
        setValues({
          data: {},
          errors: {},
          status: '',
        });

        setValues((prevState) => ({
          ...prevState,
          status: {
            ...values.status,
            status: 'success',
          },
        }));
      });
    }
  };

  return (
    <div className="form-hubspot__form-wrapper">
      {!values.status && (
        <h2 className="form-hubspot__form-title">BUILD YOUR WAREHOUSE</h2>
      )}
      {!values.status && (
        <p className="form-hubspot__form-description">
          Experience it first-hand! Fill out the form below and we will reach
          out soon!
        </p>
      )}
      {!values.status ? (
        <Form name="registration" noValidate>
          <div className="form-box__row">
            <div className="form-box__col">
              <Field
                onChange={onChangeField}
                name="firstname"
                defaultValue={get(values.data, 'firstname', '')}
                placeholder="First Name *"
                error={get(values.errors, 'firstName', false)}
                maxLength={100}
                required
              />
            </div>
            <div className="form-box__col">
              <Field
                onChange={onChangeField}
                name="lastname"
                defaultValue={get(values.data, 'lastname', '')}
                placeholder="Last Name *"
                error={get(values.errors, 'lastName', false)}
                maxLength={100}
                required
              />
            </div>
          </div>
          <div className="form-box__row_without-flex">
            <Field
              onChange={onChangeField}
              name="email"
              defaultValue={get(values.data, 'email', '')}
              placeholder="E-mail *"
              error={get(values.errors, 'email', false)}
              maxLength={100}
              required
            />
          </div>
          <div className="form-box__row">
            <div className="form-box__col">
              <Field
                onChange={onChangeField}
                name="company"
                defaultValue={get(values.data, 'company', '')}
                placeholder="Company Name *"
                error={get(values.errors, 'company', false)}
                maxLength={100}
                required
              />
            </div>
            <div className="form-box__col">
              <Field
                onChange={onChangeField}
                name="jobtitle"
                defaultValue={get(values.data, 'jobtitle', '')}
                placeholder="Job title *"
                error={get(values.errors, 'job', false)}
                maxLength={100}
                required
              />
            </div>
          </div>
          <div className="form-box__row_without-flex">
            <Field
              onChange={onChangeField}
              name="message"
              field="textarea"
              value={get(values.data, 'message', '')}
              placeholder="Have something to say? Drop us a message..."
              error={get(values.errors, 'message', false)}
              maxLength={2000}
              required
            />
          </div>
          {get(values.errors, 'fillOutFields', false) && (
            <div className="form-input__error">
              {values.errors.fillOutFields}
            </div>
          )}
          <div className="form-box__col" id="form-captcha">
            <ReCaptcha
              sitekey={process.env.RECAPTCHA_KEY}
              verifyCallback={onVerifyCaptcha}
              action="submit"
            />
            {get(values.errors, 'captcha', false) && (
              <div className="form-input__error">{values.errors.captcha}</div>
            )}
          </div>
          <Field
            field="checkbox"
            name="updates_and_offers"
            value={get(values.data, 'updates_and_offers', '')}
            label="I would like to be updated on SoftServe's latest products, event announcements, and thought leadership."
            onChange={onChangeField}
          />
          <p className="form-box__description">
            By clicking “Let's Talk”, you agree with our{' '}
            <a href="https://www.softserveinc.com/en-us/terms-and-conditions">
              Terms & Conditions
            </a>{' '}
            and{' '}
            <a href="https://www.softserveinc.com/en-us/privacy-policy">
              Privacy Policy
            </a>
            .
          </p>
          <button
            type="button"
            onClick={submitForm}
            className="form-hubspot__form-button"
          >
            Let's Talk
          </button>
        </Form>
      ) : (
        <div className="form-hubspot__success">
          <h2 className="form-hubspot__form-title">
            THANK YOU FOR SUBMITTING YOUR REQUEST!
          </h2>
          <p className="form-hubspot__form-description">
            We have received your request and look forward to sharing what
            WarehouseSim can do for your team soon.
          </p>
        </div>
      )}
    </div>
  );
};

export default RegistrationForm;
