import title_icon_1 from '../../../../../asset/resource/title-icon-1.png';
import title_icon_2 from '../../../../../asset/resource/title-icon-2.png';
import title_icon_3 from '../../../../../asset/resource/title-icon-3.png';

export const data = {
  title: 'WAREHOUSE (RE)DESIGN AND OPTIMIZATION',
  description: `Picture simulating every facet of your warehouse, creating a digital twin where you can test changes before incurring real-world costs. Unlike physical trials, virtual simulations can be conducted at any time.`,
  subDescription: `SoftServe’s WarehouseSim allows you to virtually build, operate, and redesign your warehouse, while optimizing the productivity of both mobile robots (AGVs) and personnel.`,
  items: [
    {
      image: title_icon_1,
      number: 'Item 1',
      title: 'Create:',
      description:
        'Design your warehouse effortlessly. Dynamically test changes and adjustments until you find the best fit for your warehouse needs.',
    },
    {
      image: title_icon_2,
      number: 'Item 2',
      title: 'Automate:',
      description:
        'Establish a comprehensive environment for testing, optimizing mission control, mobile robots and forklifts, and conducting post-mortem analysis.',
    },
    {
      image: title_icon_3,
      number: 'Item 3',
      title: 'Optimize: ',
      description:
        'Boost your operations effectively. Identify bottlenecks, optimize resource utilization, and maximize productivity.',
    },
  ],
};
