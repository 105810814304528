import React from 'react';
import { motion } from 'framer-motion';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { sanitizeHtml } from 'utils';

//Assets
import { data } from './data';

import { useCheckMobile } from '../../../../hooks';

import './UseCases.scss';

const UseCases = () => {
  const isMobile = useCheckMobile({ width: 767 });

  return (
    <section id="use-cases" className="use-cases">
      <div className="use-cases__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
        >
          <h2>
            <span className="sub-title">{data.subTitle}</span>
          </h2>
        </motion.div>
        <div className="use-cases__slider-wrapper">
          <Carousel
            showArrows={true}
            infiniteLoop
            showThumbs={false}
            showStatus={false}
            swipeScrollTolerance={20}
            preventMovementUntilSwipeScrollTolerance={40}
          >
            {data.items.map(({ number, title, description, image }) => (
              <div className="use-cases__slide" key={number}>
                <article>
                  <h3>{title}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(description),
                    }}
                  />
                </article>
                <figure className="use-cases__image">
                  <img
                    width="100%"
                    src={image}
                    className="slider-image"
                    alt=""
                  />
                </figure>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default UseCases;
