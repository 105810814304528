const navLinks = [
  {
    id: 1,
    title: 'The Technology',
    anchor: 'technology',
  },
  {
    id: 2,
    title: 'Use Cases',
    anchor: 'use-cases',
  },
  // {
  //   id: 4,
  //   title: 'Features',
  //   anchor: 'features',
  // },
  {
    id: 5,
    title: 'Build Your Warehouse',
    anchor: 'contact-us',
  },
];

export default navLinks;
